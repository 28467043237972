<template>
  <div class="home">
    <!-- <van-nav-bar
      title="券码详情"
      left-arrow
      @click-left="onClickLeft"
      :border="false"
    /> -->
    <div class="detail">
      <div class="activityinfo">
        <div class="axtiveInfo">
          <div class="user-img">
            <img :src="activityInfo.image" alt="" />
          </div>
          <div class="info">
            <div class="info-title">
              <div class="num">{{ activityInfo.num_limit }}人</div>
              <div class="txt van-ellipsis">{{ activityInfo.title }}</div>
            </div>
            <div class="info-time">
              <img src="../assets/img/time.png" alt="" />
              <div>{{ activityInfo.activity_time }}</div>
            </div>
            <div class="info-address">
              <img src="../assets/img/address.png" alt="" />
              <div class="van-ellipsis">{{ activityInfo.event_venue }}</div>
            </div>
          </div>
        </div>
      </div>
      <img class="verity" :src="activityInfo.saveDir" alt="" />
      <div class="ma">
        券码:{{ activityInfo.exchange_code
        }}<span
          class="copy"
          v-clipboard:copy="activityInfo.exchange_code"
          v-clipboard:success="onCopy"
          v-clipboard:error="onError"
          >复制</span
        >
      </div>
      <div class="tiptxt">温馨提示</div>
      <p>1.活动入场需携带身份证件及活动券码</p>
      <p>2.每场活动每人仅可购买一次，一次仅可一人使用</p>
      <p>3.活动结束劵码立即失效，请准时参加活动</p>
      <p>4.下单后24小时内无理由退款，秒到账</p>
    </div>
  </div>
</template>

<script>
import { codeDetail } from "@/api/activity";
export default {
  name: "Home",
  components: {},
  data() {
    return {
      active: 0,
      activityInfo: {}
    };
  },
  created() {
    console.log(this.$route.query.rec_id, 211);
    this.getDetail(this.$route.query.rec_id);
  },
  methods: {
    onClickLeft() {
      this.$router.back();
    },
    async getDetail(rec_id) {
      const res = await codeDetail({ rec_id });
      this.activityInfo = res.data.data;
      console.log(res.data.data, 9895);
    },
    onCopy() {
      this.$toast("复制成功");
    },
    onError() {
      this.$toast("复制失败");
    }
  }
};
</script>
<style lang="scss" scoped>
/deep/.van-icon-arrow-left::before {
  color: #323233;
}
/deep/.van-nav-bar__content {
  background-color: #f5f6fa;
}
.home {
  background-color: #f5f6fa;
  width: 100%;
  min-height: 100vh;
  box-sizing: border-box;
  padding-top: 30px;
  .detail {
    background: url(../assets/img/quan02.png) 0 0 no-repeat;
    background-size: 100%;
    width: 681px;
    height: 1244px;
    box-sizing: border-box;
    min-height: 100vh;
    padding: 30px 33px;
    margin: 0 30px 0;
    .activityinfo {
      width: 100%;
      background-color: #fff;
      //padding: 42px 28px 42px;
      box-sizing: border-box;
      display: flex;
      .axtiveInfo {
        display: flex;
        .user-img {
          width: 174px;
          height: 174px;
          border-radius: 14px;
          margin-right: 14px;
          img {
            width: 174px;
            height: 174px;
            border-radius: 14px;
          }
        }
        .info {
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          padding: 12px 0;
          .info-title {
            display: flex;
            .num {
              max-width: 140px;
              height: 33px;
              background: #f45b52;
              border-radius: 3px;
              text-align: center;
              line-height: 37px;
              color: #fff;
              padding: 0 8px;
              margin-right: 8px;
            }
            .txt {
              //height: 31px;
              font-size: 33px;
              //width: 380px;
              max-width: 327px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              font-family: Source Han Sans CN;
              font-weight: 600;
              color: #333333;
            }
          }
          .info-time {
            font-size: 25px;

            font-weight: 400;
            color: #999999;
            display: flex;
            img {
              width: 28px;
              height: 28px;
              margin-right: 8px;
            }
          }
          .info-address {
            font-size: 25px;
            font-weight: 400;
            color: #999999;
            display: flex;
            width: 460px;
            img {
              width: 28px;
              height: 28px;
              margin-right: 8px;
            }
          }
        }
      }
    }
    .verity {
      width: 401px;
      height: 397px;
      margin: 121px auto 68px;
      display: flex;
      justify-content: center;
    }
    .ma {
      width: 611px;
      height: 63px;
      background: #eeeeee;
      border: 1px solid #eeeeee;
      border-radius: 31px;
      //   text-align: center;
      line-height: 63px;
      padding: 0 28px;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      .copy {
        color: #999999;
      }
    }
    .tiptxt {
      font-size: 28px;
      color: #999999;
      margin: 80px 0 15px;
    }
    p {
      color: #999999;
    }
  }
}
</style>
